import React from "react"
import Layout from "../components/layouts/page"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

const Index = () => {
  return (
    <div className="page">
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Southern California Limo Service - Devine Ride</title>
        <meta
          name="description"
          content="Southern California Limo Service for Corporate Travel, Weddings, Concerts, Sport Events, and Airport Transportation."
        />
      </Helmet>
      <Layout>
        <section>
          <div className="bg-dark text-light py-5">
            <div className="container-xxl py-5">
              <div className="d-flex justify-content-center">
                <div className="col-md-8 text-center">
                  <h1 className="display-1 fw-medium mb-3">
                    Southern California Limo & Executive Car Service
                  </h1>
                  <p className="fs-5 mb-4">
                    Our goal is to make your transportation a smooth and
                    enjoyable experience, ensuring punctuality each step of the
                    way.
                  </p>
                  <div className="row row-cols-1 row-cols-md-2 g-4">
                    <div className="d-flex justify-content-center justify-content-md-end">
                      <a
                        href="/quote"
                        className="btn btn-lg btn-primary"
                        role="button"
                      >
                        Request a quote
                      </a>
                    </div>
                    <div className="d-flex justify-content-center justify-content-md-start">
                      <a
                        href="/faqs#rates"
                        className="btn btn-lg btn-outline-light"
                        role="button"
                      >
                        View our rates
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-dark-white">
            <div className="container-xxl">
              <StaticImage
                src="../images/southern-california-limo.jpg"
                alt="A limo driving through downtown Los Angeles"
              />
            </div>
          </div>
        </section>
        <section className="py-5">
          <div className="container-xxl py-5">
            <div className="d-flex mb-5">
              <div className="col-xl-6 col-lg-8">
                <h2 className="display-2 fw-medium">
                  Luxury chauffeured limos for business and leisure
                </h2>
                <p className="fs-5">
                  Our Southern California limos are available 24 hours a day, 7
                  days a week for any celebration or corporate event.
                </p>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4">
              {/* <a href="/" className='text-decoration-none text-dark'> */}
              <div>
                <div className="card rounded">
                  <StaticImage
                    src="../images/corporate-transportation.jpg"
                    alt="Business and corporate transportation"
                    className="card-img-top w-100"
                  />
                  <div className="card-body">
                    <p className="card-title fs-5 fw-bold">Corporate travel</p>
                    <p className="card-text">
                      Arrive refreshed and in style to that next client meeting,
                      corporate event, site tour, or airport dropoff.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                {/* </a>
							<a href="/" className='text-decoration-none text-dark'> */}
                <div className="card rounded">
                  <StaticImage
                    src="../images/wedding-transportation.jpg"
                    alt="Wedding transportation shuttles and limos"
                    className="card-img-top w-100"
                  />
                  <div className="card-body">
                    <p className="card-title fs-5 fw-bold">
                      Wedding transportation
                    </p>
                    <p className="card-text">
                      We can transport the bridal party, groomsmen, and family
                      to and from venues – or even act as the getaway car to
                      sweep you off to your honeymoon.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                {/* </a>
							<a href="/" className='text-decoration-none text-dark'> */}
                <div className="card rounded">
                  <StaticImage
                    src="../images/wine-tour.jpg"
                    alt="A group of friends on a wine tour tasting the best of napa valley"
                    className="card-img-top w-100"
                  />
                  <div className="card-body">
                    <p className="card-title fs-5 fw-bold">Wine Tours</p>
                    <p className="card-text">
                      Discover great wineries and taste the world-class wines in
                      Southern California while being transported in luxury.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                {/* </a>
							<a href="/" className='text-decoration-none text-dark'> */}
                <div className="card rounded">
                  <StaticImage
                    src="../images/airport-transportation-lax.jpg"
                    alt="Wedding transportation shuttles and limos"
                    className="card-img-top w-100"
                  />
                  <div className="card-body">
                    <p className="card-title fs-5 fw-bold">
                      Airport transportation
                    </p>
                    <p className="card-text">
                      Eliminate stress from the dreaded business trip when you
                      get transported to and from the airport in a comfortable
                      vehicle.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                {/* </a>
							<a href="/" className='text-decoration-none text-dark'> */}
                <div className="card rounded">
                  <StaticImage
                    src="../images/group-transportation.jpg"
                    alt="Limos for groups"
                    className="card-img-top w-100"
                  />
                  <div className="card-body">
                    <p className="card-title fs-5 fw-bold">
                      Group transportation
                    </p>
                    <p className="card-text">
                      We can help with the transportation of any group
                      regardless of the size, requirements or destination.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                {/* </a>
							<a href="/" className='text-decoration-none text-dark'> */}
                <div className="card rounded">
                  <StaticImage
                    src="../images/concert-transportation.jpg"
                    alt="Arriving at a concert in style"
                    className="card-img-top w-100"
                  />
                  <div className="card-body">
                    <p className="card-title fs-5 fw-bold">
                      Concerts & sports events
                    </p>
                    <p className="card-text">
                      Get the whole group together and have the night of your
                      life when you arrive in the plush accomodations of a
                      sprinter limo.
                    </p>
                  </div>
                </div>
              </div>
              {/* </a> */}
            </div>
          </div>
        </section>
        <section className="bg-light">
          <div className="container-xxl py-5">
            <div className="py-5">
              <div className="mb-5">
                <h2 className="display-2 fw-medium text-center">
                  Our fleet of limos
                </h2>
              </div>
              <div className="row row-cols-1 g-4">
                <div>
                  <div className="card p-4">
                    <div className="row g-0">
                      <StaticImage
                        src="../images/orange-county-limo.jpg"
                        alt="Arriving at a concert in style"
                        className="img-fluid col-lg-4"
                      />
                      <div className="col-lg-8">
                        <div className="card-body">
                          <h3 className="display-3 fw-medium">The Road King</h3>
                          <div className="mb-5 d-md-flex fs-5 justify-content-between">
                            <div>Up to 7 passengers</div>
                            <div>$200 - $250 per hour</div>
                            <div>5 hour minimum</div>
                          </div>
                          <a href="/quote" className="btn btn-primary me-3">
                            Request a quote
                          </a>
                          <a
                            href="/vehicles#road-king"
                            className="btn btn-outline-dark"
                          >
                            View more details
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card p-4">
                    <div className="row g-0">
                      <StaticImage
                        src="../images/corporate-shuttle.jpg"
                        alt="Arriving at a concert in style"
                        className="img-fluid col-lg-4"
                      />
                      <div className="col-lg-8">
                        <div className="card-body">
                          <h3 className="display-3 fw-medium">
                            Corporate Shuttle
                          </h3>
                          <div className="mb-5 d-md-flex fs-5 justify-content-between">
                            <div>Up to 14 passengers</div>
                            <div>$100 - $125 per hour</div>
                            <div>5 hour minimum</div>
                          </div>
                          <a href="/quote" className="btn btn-primary me-3">
                            Request a quote
                          </a>
                          <a
                            href="/vehicles#corporate-shuttle"
                            className="btn btn-outline-dark"
                          >
                            View more details
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card p-4">
                    <div className="row g-0">
                      <StaticImage
                        src="../images/party-bus-los-angeles.jpg"
                        alt="Arriving at a concert in style"
                        className="img-fluid col-lg-4"
                      />
                      <div className="col-lg-8">
                        <div className="card-body">
                          <h3 className="display-3 fw-medium">
                            The Night Rider
                          </h3>
                          <div className="mb-5 d-md-flex fs-5 justify-content-between">
                            <div>Up to 14 passengers</div>
                            <div>$125 - $175 per hour</div>
                            <div>5 hour minimum</div>
                          </div>
                          <a href="/quote" className="btn btn-primary me-3">
                            Request a quote
                          </a>
                          <a
                            href="/vehicles#night-rider"
                            className="btn btn-outline-dark"
                          >
                            View more details
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card p-4">
                    <div className="row g-0">
                      <StaticImage
                        src="../images/los-angeles-limo.jpg"
                        alt="Arriving at a concert in style"
                        className="img-fluid col-lg-4"
                      />
                      <div className="col-lg-8">
                        <div className="card-body">
                          <h3 className="display-3 fw-medium">The Weekender</h3>
                          <div className="mb-5 d-md-flex fs-5 justify-content-between">
                            <div>Up to 8 passengers</div>
                            <div>$150 - $200 per hour</div>
                            <div>5 hour minimum</div>
                          </div>
                          <a href="/quote" className="btn btn-primary me-3">
                            Request a quote
                          </a>
                          <a
                            href="/vehicles#weekender"
                            className="btn btn-outline-dark"
                          >
                            View more details
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default Index
